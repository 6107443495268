import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GuestCard from '../components/auth/guestcard/GuestCard';
import { SignupProps } from '../components/auth/interfaces/auth.interface';
import ResetPassword from '../components/auth/resetpassword/ResetPassword';
import UnitDetails from '../components/locations/units/UnitDetails';
import UnitsDashboard from '../components/locations/units/UnitsDashboard';
import NotFound from '../components/not-found/NotFound';
import AppointmentPage from '../pages/AppointmentPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import LocationsPage from '../pages/LocationsPage';
import LoginPage from '../pages/LoginPage';
import SignupPage from '../pages/SignupPage';
import Tour from '../pages/Tour';
import Layout from './layout/Layout';
import OuterLayout from './outerlayout/OuterLayout';
import ProtectedRoutes from './ProtectedRoutes';
import ProfilePage from '../pages/ProfilePage';
import ChangePasswordPage from '../pages/ChangePasswordPage';
import ComingSoonPage from '../pages/ComingSoon/ComingSoonPage';
import TourComplete from '../pages/TourComplete';
import MappedInComponent from '../components/mappit/NewMappit';

const Templates = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [signupDetails, setSignupDetails] = useState<SignupProps>({
        fullName: '',
        email: '',
        password: '',
        phoneNumber: 0,
        stripeDocVerificationClientId: '',
        stripeCustomerId: '',
        terms: false,
        firstName: '',
        lastName: '',
        middleName: '',
        namePrefix: 'Mr.',
        addressLine1: '29 Birch Lane',
        addressLine2: 'Carlisle',
        country: 'USA',
        state: 'MA',
        city: 'Carlisle',
        postalCode: '01741',
        expectedMoveInDate: '',
        leaseFromDate: '',
        leaseToDate: '',
        desiredNumBedrooms: 'Studio',
        desiredLeaseTerms: '',
        countryCode: 0,
        pmsLeadSorce: {
            lead_id: '',
            lead_name: '',
            lead_PMS: '',
        },
    });

    return (
        <>
            <Helmet>
                <title>Visitor - Dashboard</title>
            </Helmet>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<ProtectedRoutes />}>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<AppointmentPage />} />
                            <Route path="/appointments" element={<AppointmentPage />} />
                            <Route path="/locations" element={<LocationsPage />} />
                            <Route path="/location/:id/unit/:unitid" element={<UnitDetails />} />
                            {/* <Route path='/tour' element={<Tour />} /> */}
                            <Route path="/location/:id" element={<UnitsDashboard />} />
                            {/* <Route path="/location-map/:id/:appointmentId/:chatBotId" element={<LocationMap />} />
                            <Route path="/location-map" element={<LocationMap />} /> */}
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/change-password" element={<ChangePasswordPage />} />
                            <Route path="/location-map/coming-soon" element={<ComingSoonPage />} />
                            <Route path="/tour-completion/:id" element={<TourComplete />} />
                            <Route path="/mappit" element={<MappedInComponent />} />
                            <Route path="map-view/:id" element={<MappedInComponent />} />
                        </Route>
                    </Route>
                    <Route path="/location/tour/:id/unit/:unitid/appointment/:appointmentId" element={<Tour />} />
                    <Route path="*" element={<NotFound />} />

                    <Route path="/" element={<OuterLayout />}>
                        <Route path="/signup" element={<SignupPage />} />
                        <Route
                            path="/guest"
                            element={<GuestCard setIsOpen={setIsOpen} signupDetails={signupDetails} setSignupDetails={setSignupDetails} />}
                        />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                        <Route path="/reset-password/:token" element={<ResetPassword />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default Templates;
